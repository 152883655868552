import React, { useState } from "react";
import { navigate, Link } from "gatsby";
import Carousel from "react-responsive-carousel/lib/js/components/Carousel/index";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Layout from "../components/layout";
import imageDecor from "../images/photos/xwni.png";
import imageDecor2 from "../images/photos/decor2.png";
import Avatar1 from "../images/avatars/team/avatar1.png";
import IconMissionUser from "../images/icons/pages/about-us/mission-user.svg";
import IconMissionStar from "../images/icons/pages/about-us/mission-star.svg";
import IconMissionCustomer from "../images/icons/pages/about-us/mission-customer.svg";
import SvgDivider1 from "../images/dividers/divider-1.svg";
// import useInViewPort from "../hooks/useInViewPort";

// import { AboutUsJsonLd } from "../components/jsonld/AboutUsJsonLd";

const AboutUsPage = ({ data, location: { pathname } }) => {
  const [productTab, setProductTab] = useState(0);

  const productTabHandler = (tab) => {
    setProductTab(tab);
  };
  return (
    <Layout>
      {/* <AboutUsJsonLd /> */}

      <div className="about-us-page">
        <section className="bg-primary-alt has-divider first-section">
          {/* <DecorationLine4 className="d-none d-lg-inline decoration-lines-4" /> */}
          <div className="container pb-2">
            <div className="row align-items-center justify-content-between o-hidden">
              <div className="col-10 col-lg-7 my-0 mx-auto text-center">
                <h1 className="display-4">Filter your channels and keep the Meaning</h1>

                <p className="lead m-0">
                ALL IN ONE DASHBOARD - ACCESS YOUR DATA FROM EVERY PLATFORM YOU POST AND ADVERTISE
                </p>
              </div>
            </div>
          </div>

          <div className="d-none d-lg-flex justify-content-between align-items-center decoration-container">
            <div className="decoration-wrapper">
              <div className="decoration position-absolute scale-1">
              <picture>
                    <source type="image/png" srcSet={imageDecor} />
                    <source srcSet={imageDecor} type="image/png" />
                    <img src={imageDecor} alt="" />
                  </picture>
              </div>
              <div className="decoration position-absolute scale-1">
              </div>
            </div>
          </div>

          <div className="divider">
            <SvgDivider1 />
          </div>
        </section>

        <section className="w-100 p-0 mt-0 pt-xl-4">
          <div className="container p-0">
            <div className="row d-none d-xl-flex align-items-center justify-content-between o-hidden">
              <div className="col-10 col-lg-8 p-0 my-0 mx-auto text-center">
              <picture>
                    <source type="image/png" srcSet={imageDecor2} />
                    <source srcSet={imageDecor2} type="image/png" />
                    <img src={imageDecor2} alt="" />
                  </picture>
                <h2 className="heading-two">Vision</h2>
                <p className="lead description m-0">
                Integer lacinia sollicitudin massa. Cras metus. Sed aliquet risus a tortor.
                 Integer id quam. Morbi mi. Quisque nisl felis, venenatis tristique, 
                 dignissim in, ultrices sit amet, augue. Proin sodales libero 
                 eget ante. Nulla quam. 
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="second-section p-0 mt-3">
          <div className="container p-0 mw-100">
            <div className="row mt-1 mt-xl-6">
              <div className="col-md-12 col-xl-6 pl-5 pr-5 pl-xl-0 pr-xl-0">
                {/* <GatsbyImage
                  className="m-md-auto m-xl-0 vision-img"
                  loading={"lazy"}
                  image={visionImg}
                  alt="Vision"
                /> */}
              </div>

              <div className="col-10 d-xl-none p-2 my-2 mx-auto">
                <h2 className="heading-two">Vision</h2>

                <p className="lead description m-0">
                Integer lacinia sollicitudin massa. Cras metus. Sed aliquet risus a tortor.
                 Integer id quam. Morbi mi. Quisque nisl felis, venenatis tristique, 
                 dignissim in, ultrices sit amet, augue. Proin sodales libero 
                 eget ante. Nulla quam.
                </p>
              </div>

              <div className="col-md-12 col-xl-6 impacts-container">
                <div className="impact-wrapper">
                  <h3 className="impact-heading"> Business impact:</h3>
                  <ul className="impact-benefit-list">
                    <li className="impact-benefit-list-item">
                    Quisque nisl felis
                    </li>
                    <li className="impact-benefit-list-item">
                    Quisque nisl felis
                    </li>
                    <li className="impact-benefit-list-item">
                    Quisque nisl felis
                    </li>
                  </ul>
                </div>
                <div className="impact-wrapper">
                  <h3 className="impact-heading"> Quisque nisl felis:</h3>
                  <ul className="impact-benefit-list">
                    <li className="alternate-list-item">
                      <span>
                      Integer lacinia sollicitudin massa. (and counting)
                      </span>
                    </li>
                    <li className="alternate-list-item">
                      <span>
                      Integer lacinia sollicitudin massa. Cras metus. Sed aliquet risus a tortor.
                 Integer id quam. Morbi mi.
                      </span>
                    </li>
                    <li className="alternate-list-item">
                      <span>
                      Integer lacinia sollicitudin massa. Cras metus. Sed aliquet risus a tortor.
                 Integer id quam. Morbi mi.
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="p-0 pt-2 pb-md-0 pt-md-6 third-section has-divider">
          <div className="container">
            <div className="row mt-2 mb-4 d-lg-none">
              <div className="col-12">
                {/* <GatsbyImage
                  className="mission-img"
                  loading={"lazy"}
                  image={missionImg}
                  alt="Mission"
                /> */}
              </div>
            </div>

            <div className="row align-items-center justify-content-between o-hidden">
              <div className="col-10 col-lg-8 p-2 my-0 mx-auto text-lg-center">
                <h2 className="heading-two">Mission</h2>

                <p className="lead description m-0">
                Integer lacinia sollicitudin massa. Cras metus. Sed aliquet risus a tortor.
                 Integer id quam. Morbi mi.Integer lacinia sollicitudin massa. Cras metus. Sed aliquet risus a tortor.
                 Integer id quam. Morbi mi.
                </p>
              </div>
            </div>

            <div className="row mt-2 mt-lg-4">
              <div className="col-md-12 col-lg-5 p-0">
                <div className="mission-list-container">
                  <div className="mission-list-item">
                    <div className="mission-list-icon-container">
                      <IconMissionUser
                        className="mission-list-icon"
                        alt="users' lives impacted"
                      />
                    </div>
                    <div className="mission-list-description">
                      <span className="mission-highlight">20M+</span>
                      <span className="mission-description">
                        user's lives impacted
                      </span>
                    </div>
                  </div>

                  <div className="mission-list-item">
                    <div className="mission-list-icon-container">
                      <IconMissionStar
                        className="mission-list-icon"
                        alt="users' lives impacted"
                      />
                    </div>
                    <div className="mission-list-description">
                      <span className="mission-highlight">~4.7 rating</span>
                      <span className="mission-description">
                        with our apps
                      </span>
                    </div>
                  </div>

                  <div className="mission-list-item">
                    <div className="mission-list-icon-container">
                      <IconMissionCustomer
                        className="mission-list-icon"
                        alt="users' lives impacted"
                      />
                    </div>
                    <div className="mission-list-description">
                      <span className="mission-highlight">4.9/5</span>
                      <span className="mission-description">
                        avg. customer satisfaction score
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-7 col-md-12 d-none d-md-inline">
                {/* <GatsbyImage
                  className="mission-img"
                  loading={"lazy"}
                  image={missionImg}
                  alt="Mission"
                /> */}
              </div>
            </div>
          </div>

          <div className="divider mission-divider mt-lg-4">
            <SvgDivider1 />
          </div>
        </section>

        <section className="fourth-section p-0">
          <div className="container p-2">
            <div className="row align-items-center justify-content-between o-hidden">
              <div className="col-10 col-lg-8 p-0 my-0 mx-4 mx-md-auto text-md-center">
                <h2 className="heading-two">What we Do</h2>
              </div>
            </div>
          </div>

          <div className="container mt-3 pl-4 p-md-0 swiper-buttons-container">
            <button
              className="d-none d-lg-inline-block swiper-buttons"
              onClick={() => productTabHandler(0)}
              style={{
                borderBottom: productTab === 1 ? "none" : "2px solid #479dff7",
                color: productTab === 1 ? "#6C757D" : "#479dff7",
              }}
            >
              Morbi lacinia molestie dui
            </button>
            <button
              className="d-none d-lg-inline-block swiper-buttons"
              onClick={() => productTabHandler(1)}
              style={{
                borderBottom: productTab === 0 ? "none" : "2px solid #479dff",
                color: productTab === 0 ? "#6C757D" : "#479dff",
              }}
            >
              Improving an Existing Product
            </button>
            <button
              className="d-lg-none swiper-buttons"
              onClick={() => productTabHandler(0)}
              style={{
                borderBottom: productTab === 1 ? "none" : "2px solid #479dff",
                color: productTab === 1 ? "#6C757D" : "#479dff7",
              }}
            >
              MVP
            </button>
            <button
              className="d-lg-none swiper-buttons"
              onClick={() => productTabHandler(1)}
              style={{
                borderBottom: productTab === 0 ? "none" : "2px solid #479dff7",
                color: productTab === 0 ? "#6C757D" : "#479dff7",
              }}
            >
              Enhancement
            </button>
          </div>

          <Carousel
            showArrows={false}
            showStatus={false}
            showIndicators={false}
            showThumbs={false}
            selectedItem={productTab === 0 ? 0 : 1}
            autoPlay={false}
            animationHandler="fade"
            swipeable={false}
            transitionTime={100}
          >
            <div>
              <div className="container mt-6">
                <div className="row align-items-center justify-content-between o-hidden">
                  <div className="col-10 col-lg-8 p-0 my-0 mx-auto text-left text-md-center">
                    <h2 className="swiper-heading">
                    Morbi lacinia molestie dui
                    </h2>

                    <p className="swiper-description m-0">
                    Suspendisse in justo eu magna luctus suscipit. 
                    Sed lectus. Integer euismod lacus luctus magna. 
                    Quisque cursus, metus vitae pharetra auctor, 
                    sem massa mattis sem, at interdum magna augue eget diam
                    </p>
                  </div>
                </div>
              </div>

              <div className="container mt-6">
                <div className="row align-items-center justify-content-between o-hidden">
                  <div className="col-10 col-lg-8 p-0 my-0 mx-auto text-left text-md-center">
                    <h2 className="swiper-heading">Here's how it works:</h2>
                  </div>
                </div>
              </div>

              <div className="container mx-0 my-0 mw-100">
                <div className="row align-items-center justify-content-between o-hidden">
                  <div className="col-10 col-lg-8 p-0 my-0 mx-auto text-center">
                    {/* <GatsbyImage
                      className="ux-img"
                      loading={"lazy"}
                      image={uxImg}
                      alt="UX"
                    /> */}
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="container mt-6">
                <div className="row align-items-center justify-content-between o-hidden">
                  <div className="col-10 col-lg-8 p-0 my-0 mx-auto text-left text-md-center">
                    <h2 className="swiper-heading">
                    Suspendisse in justo eu magna luctus suscipit
                    </h2>

                    <p className="swiper-description m-0">
                    Suspendisse in justo eu magna luctus suscipit. 
                    Sed lectus. Integer euismod lacus luctus magna. 
                    Quisque cursus, metus vitae pharetra auctor,
                    sem massa mattis sem, at interdum magna augue eget diam
                    </p>
                  </div>
                </div>
              </div>

              <div className="container mt-6">
                <div className="row align-items-center justify-content-between o-hidden">
                  <div className="col-10 col-lg-8 p-0 my-0 mx-auto text-left text-md-center">
                    <h2 className="swiper-heading">Here's how it works:</h2>
                  </div>
                </div>
              </div>

              <div className="container mx-0 my-0 mw-100">
                <div className="row align-items-center justify-content-between o-hidden">
                  <div className="col-10 col-lg-8 p-0 my-0 mx-auto text-center">
                    {/* <GatsbyImage
                      className="ux-img"
                      loading={"lazy"}
                      image={uxImg2}
                      alt="UX"
                    /> */}
                  </div>
                </div>
              </div>
            </div>
          </Carousel>

          <div className="container mt-6 mb-3">
            <div className="row align-items-center justify-content-between o-hidden">
              <div className="col-10 col-lg-8 p-0 my-0 mx-auto text-center">
                <button
                  onClick={() => {
                    navigate("/ux-design-process");
                  }}
                  className="process-cta"
                >
                  See our Full Process Here
                </button>
              </div>
            </div>
          </div>

          <div className="divider swiper-divider bg-white">
            <SvgDivider1 />
          </div>
        </section>

        <section className="fifth-section text-white has-divider">
          <div className="container pt-0">
            <div className="row mt-7">
              <div className="col-md-12 col-lg-7 pl-3 pr-3 p-lg-0">
                <h2 className="product-design-heading2">Our Team </h2>
                <ul className="team-list">
                  <li className="team-list-item">
                  Duis sagittis ipsum.;
                  </li>
                  <li className="team-list-item">
                  Duis sagittis ipsum.;
                  </li>
                  <li className="team-list-item">
                  Duis sagittis ipsum;
                  </li>
                  <li className="team-list-item">
                  Duis sagittis ipsum;
                  </li>
                </ul>

              </div>

              <div className="col-md-12 col-lg-5 text-center">
                <div className="team-wrapper m-auto d-inline d-lg-inline-flex">
                  

                  <img
                    src={Avatar1}
                    className="team-member"
                    alt="team-member"
                  />

                  <img
                    src={Avatar1}
                    className="team-member"
                    alt="team-member"
                  />

                  <img
                    src={Avatar1}
                    className="team-member"
                    alt="team-member"
                  />

                  <img
                    src={Avatar1}
                    className="team-member"
                    alt="team-member"
                  />

                  <img
                    src={Avatar1}
                    className="team-member"
                    alt="team-member"
                  />

                  <img
                    src={Avatar1}
                    className="team-member"
                    alt="team-member"
                  />
                 
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sixth-section bg-primary-3 text-white has-divider">
          <div className="container">
            <div className="row top">
              <div className="col-md-6 pl-2 pl-md-0">
                <h3 className="h1 text-white">Duis sagittis ipsum;</h3>
                <p className="lead">
                Duis sagittis ipsum;Duis sagittis ipsum;Duis sagittis ipsum;
                </p>
                <p className="lead">
                  <strong>
                    The result is a highly effective process ready for teams
                    willing to scale!
                  </strong>
                </p>
                <p className="lead">
                Curabitur sit amet mauris. Morbi in dui quis est pulvinar ullamcorper.
                </p>
                <p className="lead">
                Curabitur sit amet mauris. Morbi in dui quis est pulvinar ullamcorper.{" "}
                </p>

                <Link
                  to="/free-strategy-session"
                  id="freeconsultation"
                  className="mt-3 btn btn-lg btn-primary"
                  data-aos="fade-up"
                >
                  Book a Session
                </Link>
              </div>
              <div className="col-md-6 d-flex flex-column justify-content-center ">
                <div className="image mt-6 mt-md-0 d-none d-md-block">
                  {/* gatsby-image doesn't support gifs, have to convert them manually :/ */}
                  {/* <picture>
                    <source type="image/webp" srcSet={imageWorkRemotelyWebp} />
                    <source srcSet={imageWorkRemotely} type="image/gif" />
                    <img src={imageWorkRemotely} alt="" loading="lazy" />
                  </picture> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

    </Layout>
  );
};

export default AboutUsPage;
